<template>
  <div>
    <ul class="list-group mb-3" style="border-radius: 0.25rem; box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);" :class="darkMode">
      <!-- Item List -->
      <template v-for="order in orders">
        <li :key="order.id" class="list-group-item inherit-color p-4">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h6 class="my-0 oneline">{{orderMessage(order.fulfillment).toUpperCase()}}</h6>
                  <small class="text-muted">{{formatDate(order.created.toDate())}}</small>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-9">
                  <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                    <small class="text-muted">Order: {{order.id}}</small>
                  </div>
                  <div>
                    <small class="text-muted">Total: {{convertToDinero(order.totalMoney.amount)}}</small>
                  </div>
                </div>
                <div class="col d-flex justify-content-end align-items-end">
                  <router-link :to="`/account/orders/${order.id}`" tag="button" v-bind:class="darkModeBtn" class="btn btn-sm">
                    <i class="fas fa-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </template>
      <!-- Item List -->
    </ul>
    <!-- <div v-for="order in orders" :key="order.id">
      <div v-bind:class="darkMode" class="card p-3 mb-3">
        <div class="row">
          <div class="col-11 pe-1">
            <div class="no-overflow">
              <b>Order ID:</b>
              {{ order.id }}
            </div>
            <div class="no-overflow">
              <b>Order Total:</b>
              {{ formatPrice(order.totalMoney.amount) }}
            </div>
          </div>
          <div class="col-1 ps-0 d-flex justify-content-end align-items-start">
            <i class="fas fa--circle"></i>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex align-items-end">
            <div class="no-overflow d-flex align-items-end">
              <small id="orderCreated"></small>
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <router-link :to="`/account/orders/${order.id}`" tag="button" v-bind:class="darkModeBtn" class="btn btn-sm">View Order</router-link>
          </div>
        </div>
      </div>
    </div>-->
    <div class="w-100 d-flex justify-content-center mt-2">
      <p v-if="orders.length > 0 && disable">Thats all of them</p>
      <p v-if="orders.length == 0 && disable">You don't have any orders</p>
      <scroll-loader style="padding: 10px;" :loader-method="getOrders" :loader-disable="disable"></scroll-loader>
    </div>
  </div>
</template>

<script>
import { loadOrders } from '../../../assets/js/dbController';
import { darkModeStyle } from '../../../assets/js/darkMode';
import Dinero from 'dinero.js';

export default {
  data() {
    return {
      orders: [],
      disable: false,
      pageSize: 7,
      lastQuery: null,
      executing: false,
      darkMode: darkModeStyle('bg-dark text-light', 'bg-light text-dark'),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {

      if (this.executing == false) {
        this.executing = true;

        const pageParams = {
          lastQuery: this.lastQuery,
          pageSize: this.pageSize,
        }

        const query = await loadOrders(pageParams);

        this.orders = [...this.orders, ...query.response];
        this.lastQuery = query.lastQuery;

        if (query.response.length < this.pageSize) {
          this.disable = true;
        }

        this.executing = false;
      }

    },
    formatDate(date) {
      var day = date.getDate()
      var month = date.getMonth()
      var year = date.getFullYear()
      return day + "/" + month + "/" + year;
    },
    orderMessage(order) {
      switch (order) {
        case "PROPOSED":
          return "Order paid";
        case "RESERVED":
          return "Order cooking";
        case "PREPARED":
          return "Order ready";
        case "COMPLETED":
          return "Order collected";
        default:
          return "Order paid";
      }
    },
    convertToDinero(price) {
      return Dinero({ amount: price, currency: 'GBP' }).toFormat();
    },
  },
}
</script>